<template>
  <div>
    <table-compnent
      :records="tableData"
      @resetHandler="reset"
      @searchHandler="doSearch"
      @addHandler="saveRole"
      @queryByIdHandler="queryById"
      @deleteByIdHandler="deleteById"
      :showDelete="false"
      :showUpdate="false"
      :loading="loading"
    >
      <template v-slot:search>
        <el-form-item label="姓名：" class="rig">
          <el-input v-model="search.realName" placeholder="请输入"></el-input>
        </el-form-item>
        <el-form-item label="手机号：" class="rig">
          <el-input v-model="search.username" placeholder="请输入"></el-input>
        </el-form-item>
      </template>
      <template v-slot:content>
        <el-table-column prop="realName" label="姓名"></el-table-column>
        <el-table-column prop="roleName" label="角色"></el-table-column>
        <el-table-column prop="companyName" label="所属公司"></el-table-column>
        <el-table-column prop="motorcadeName" label="所属车队"></el-table-column>
        <el-table-column prop="username" label="手机号"></el-table-column>
        <el-table-column label="操作" width="100">
          <template scope="s">
        <el-button type="text" size="small" @click="queryById(s.row.no)"
          >编辑</el-button
        >
        <el-button type="text" size="small" @click="deleteById(s.row.no)"
          >删除</el-button
        >
      </template>
        </el-table-column>
      </template>
    </table-compnent>
  </div>
</template>

<script>
import listPage from "../../base/listPage.vue";
import admin from "@/api/admin";
export default {
  name: "RoleList",
  extends:listPage,
  data() {
    return {
      tableData: {},
      search: {
        realName: "",
        username: "",
      },
      page: {},
    };
  },
  methods: {
    reset() {
      this.search = this.$options.data().search;
      this.doSearch({pageSize:20,pageNum:1})
    },
    doSearch(params) {
      this.page = params;
      this.search.pageSize = this.page.pageSize;
      this.search.pageNum = this.page.pageNum;
      this.loading = true;
      admin.findAdminUserPage(this.search).then((resp) => {
        if (resp.code == "200") {
          this.tableData = resp.data;
          this.loading = false;
        }
      });
    },
    saveRole() {
      this.navTo("/saveAdmin");
    },
    queryById(no) {
      this.$router.push({
        name: "saveAdmin",
        query: {
          no,
        },
      });
    },
    deleteById(no) {
      this.$confirm("此操作将永久删除该用户, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).then(() => {
        admin.deleteAdminUser({ userNo: no }).then((resp) => {
          if (resp.code == "200") {
            this.doSearch(this.page);
            this.$successMsg(resp.msg);
          } else {
            this.$errorMsg(resp.msg);
          }
        });
      });
    },
  },
};
</script>
<style scoped>
.rig {
  margin-right: 60px;
}
</style>